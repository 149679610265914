var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"authPages"},[_c('b-row',[_c('b-col',{attrs:{"lg":"8","offset-lg":"2"}},[_c('article',{staticClass:"single-blog"},[_c('div',{staticClass:"post-content"},[_c('div',[_c('h3',{staticClass:"mt-2"},[_vm._v("Sign in")]),_c('b-alert',{attrs:{"variant":"danger","show":_vm.error.length > 0}},[_vm._v(_vm._s(_vm.error)+" ")]),_c('b-alert',{attrs:{"variant":"info","show":_vm.$route.params.justRegistered === 'true'}},[_vm._v(" Thank you for registering!"),_c('br'),_vm._v(" Open your email inbox and click the confirmation button link to activate your account. ")]),_c('b-form',{staticClass:"form-horizontal contact-form"},[_c('b-form-group',[_c('b-input',{attrs:{"type":"text","id":"email","name":"email","placeholder":"user name (email)","required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('b-form-group',{staticStyle:{"position":"relative"}},[_c('b-input',{attrs:{"type":_vm.passwordVisible ? 'text' : 'password',"id":"password","name":"password","placeholder":"password","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('i',{staticClass:"fa",class:this.passwordVisible ? 'fa-eye-slash' : 'fa-eye',staticStyle:{"font-size":"1.3em","position":"absolute","right":"10px","top":"13px","color":"#6d5a3f","cursor":"pointer"},style:(this.passwordVisible ? 'right: 10px;' : 'right: 11.5px;'),on:{"click":function () {
                      this$1.passwordVisible = !this$1.passwordVisible;
                    }}})],1),_c('b-button',{staticClass:"btn send-btn mb-4 mt-2",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.login($event)}}},[_vm._v("SIGN IN ")]),_c('div',{staticClass:"mt-2"},[_c('a',{staticClass:"btn btn-link",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (function () {
                      _vm.$router.push({ name: 'Register' });
                    })($event)}}},[_vm._v("Not yet registered?")])]),_c('div',{staticClass:"mt-2"},[_c('a',{staticClass:"btn btn-link",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (function () {
                      _vm.$router.push({ name: 'ForgottenPassword' });
                    })($event)}}},[_vm._v("Forgot your password or need to change it?")])])],1)],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }